import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css"; 


function ThanksforApplying() {

    useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration in milliseconds
        once: true, // Whether animation should happen only once
        offset: 100, // Distance before the element triggers animation
      });
    }, []);

    const handleRedirect = () =>{
      window.location.href='/'
    }
  return (
    <div className='team'>
  <div data-aos="fade-up"  data-aos-delay="200" data-aos-easing="ease-in-out" className='style-element'>
      <p >SUCESS</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='staff-title-new'> Thanks For Applying</h1>
      <p data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" className='thanks'> We are very excited to assist you on this journey! Please check your email for a message from us. One of our staff will be checking in with you shortly. </p>
      <div className='button-area '>
      <button onClick={()=>handleRedirect()} data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease-in-out" className='learn-more'>Home</button>

      </div>
    </div>
  
    
  )
}

export default ThanksforApplying