import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import LandingPage from './pages/LandingPage';
import ThanksforApplying from './pages/ThanksforApplying';
import ThanksforMessage from './pages/ThanksforMessage';
import Application from './pages/Application';
import NotFound from './pages/NotFound';
function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/success-application' element={<ThanksforApplying />} />
      <Route path='/success-message' element={<ThanksforMessage />} />
      <Route path='/application' element={<Application />} />
      <Route path="*" element={<NotFound />} />

     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
