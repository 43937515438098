import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import axios from 'axios'
import AOS from "aos";
import "aos/dist/aos.css"; 
import { useToast } from '../Context/ToastContext';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone]= useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
      offset: 100, // Distance before the element triggers animation
    });

    
  }, []);

  const handleSupportMessage = async(e) =>{
    e.preventDefault();
    const toastId = toast.loading("Loading..."); // Store toast ID

    try {
      const response = await axios.post('https://sport-scholarship-usa.onrender.com/support', {fullname, email, phone, message});
      if(response.status === 200){
        toast.dismiss(toastId); // Stop loading toast
        toast.success(response.data.message)
                setFullname('');
        setEmail('');
        setPhone('');
        setMessage('');
        
        setTimeout(() => {
          navigate('/success-message');
        }, 2000);
                return
      }
    } catch (error) {
      toast.dismiss(toastId); // Stop loading toast
      if(error.response){
        toast.error(error.response.data.message|| 'error occured')
      }
    }
  }
  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleApplicationRedirect = () =>{
    window.location.href='/application'
  }

  
  return (
    <div className='landing-page'>
      <Header />
      <div className='background-div'>
      <img className="back" src='background.jpeg' alt='South African swimmer receiving a sports scholarship in the USA' />
     </div>
      <div data-aos="fade-up" data-aos-easing="ease-in-out" className='style-element'>
      <p >Your Future Awaits</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out"  className='title'>Apply for Your Sport Scholarship in the USA</h1>
      <p data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" className='slogan'>Helping South African school leavers get high value scholarships via their sporting abilities at top colleges and Universities in USA</p>
      <div data-aos-delay="500" data-aos="fade-up" data-aos-easing="ease-in-out" className='main-buttons'>
        <button onClick={()=>handleScrollTo('process')} className='learn-more'>Learn More</button>
        <button onClick={()=>handleApplicationRedirect()} className='apply'>Apply</button>
      </div>
      <div id='about-us' className='about-us'>
      <div data-aos="fade-up"  data-aos-delay="200" data-aos-easing="ease-in-out" className='style-element'>
      <p >HERE TO HELP</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='about-us-title'> About Us</h1>
      <iframe
  data-aos="fade-up"
  data-aos-delay="300"
  data-aos-easing="ease-in-out"
  className="intro-video"
  width="560"
  height="315"
  src="new-movie.mp4"
  title="Intro Video"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen>
</iframe>
  <div  className='information-section-div'>
    <div className='left-info'>
      <h1 data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='discover'>Helping South Africans Get US Sport Scholarships</h1>
      <p data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" className='mission'>Sport Scholarship USA is a family-owned business dedicated to helping your sport talented kid get an international scholarship to allow him or her to swim competitively in the USA. With more than 20 years cumulative experience we have helped scores of swimmers from South Africa to study in the USA. </p>
    </div>
    <div className='right-info'>
      <div data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='one'>
        <img src='soccer.png' className='soccer' alt='Soccer Scholarships in the USA' />
      </div>
      <div className='two'>
        <img data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" src='tennis.png' className='tennis' alt='Tennis SCholarships in the USA' />
        <img data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease-in-out" src='swimming.png' className='swimming' alt='Swimming Scholarships in the USA' />

      </div>
    </div>
  </div>
  <div className='team'>
  <div data-aos="fade-up"  data-aos-delay="200" data-aos-easing="ease-in-out" className='style-element'>
      <p >MEET THE TEAM</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='staff-title'> Our Staff</h1>
    <div className='row-one'>
    <img data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" src='geoff.png' className='profile' alt='We help get sport scholarships in the USA'/>
    <img data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" src='liz.png' className='profile' alt='We help get sport scholarships in the USA'/>

    </div>
    <div className='row-two'>
    <img data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease-in-out" src='elmarie.png' className='profile' alt='We help South Africans get sport scholarships in the USA' />
    <img data-aos="fade-up" data-aos-delay="600" data-aos-easing="ease-in-out" src='effy.png' className='profile' alt='We help South Africans get sport scholarships in the USA' />

    </div>
  </div>
      </div>

    <div id='process' className='process'>
        <div data-aos="fade-up"  data-aos-delay="100" data-aos-easing="ease-in-out" className='style-element'>
          <p >HOW WE GET IT DONE</p>
            </div>
     <h1 data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-out" className='staff-title'> Our Process</h1>
    <p className='small-info' data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out">So your swimmer wants to study in the USA! But where to start? There are more than 5000 learning institutions in the USA. Sport Scholarship USA will target those
    schools that fit the applicant’s criteria the best.</p>
     <div className='process-div'>

      <div className='process-actual' data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" >
        <div className='process-left'>
          <h2 className='step'> Step 1</h2>
          <p className='step-title'>Analysis</p>
        </div>
        <div className='process-right'>
          <p className='step-info'>Here we analyze the swimmer’s skill set and times and match this to schools where they will have the greatest chance of being part of the team. We also match the academic achievements and study
          direction to universities and colleges that offer the right courses .</p>
        </div>
      </div>

      <div className='process-actual' data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease-in-out">
        <div className='process-left'>
          <h2 className='step'> Step 2</h2>
          <p className='step-title'>Networking</p>
        </div>
        <div className='process-right'>
          <p className='step-info'>Here we actively engage with Coaches and teams- looking for the best fit for our candidate
Some of the actions here are:
Setting up video calls with coaches and the applicant
Assist with registering for SAT’s and enrolling the applicant to an online SAT tutorial. Manage the registration to NCAA, NJCAA or NAIA and assist with the completion of University and College applications</p>
        </div>
      </div>


      <div className='process-actual' data-aos="fade-up" data-aos-delay="600" data-aos-easing="ease-in-out">
        <div className='process-left'>
          <h2 className='step'> Step 3</h2>
          <p className='step-title'>Offer Confirmation</p>
        </div>
        <div className='process-right'>
          <p className='step-info'>Once an offer or offers have been made we start the final stage where we:
Assist with VISA applications, transcript verifications, SEVIS registrations.
We help negotiate a substantial scholarship, 
advise on travel dates, items to take along, means and modes of communication etc.</p>
        </div>
      </div>
     </div>
    </div>

    <div  className='testimonials'>
    <div data-aos="fade-up"  data-aos-delay="100" data-aos-easing="ease-in-out" className='style-element'>
          <p >HEAR FROM OUR CLIENTS</p>
            </div>
     <h1 data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-out" className='staff-title'>Testimonials</h1>

     <div data-aos="fade-up"   data-aos-delay="300" data-aos-easing="ease-in-out" pauseOnHover speed={100} className='testimonial-scroll'>
      <div className='testimony'>
        <img src='quote.png' alt='quote' className='quote' />
        <p className='testi'>We were offered scholarships at 9 different Universities- my daughter could pick and choose</p>
      </div>

      <div className='testimony'>
        <img src='quote.png' alt='quote' className='quote' />
        <p className='testi'>I got a full ride and am now teaching in Singapore- I can can work anywhere in the world with my USA degree</p>
      </div>

      <div className='testimony'>
        <img src='quote.png' alt='quote' className='quote' />
        <p className='testi'>My daughter has improved her times and is loving the international feel of her campus. SSUSA really opened all the doors for us!</p>
      </div>

      <div className='testimony'>
        <img src='quote.png' alt='quote' className='quote' />
        <p className='testi'>SSUSA helped us through the whole prosess. We saved $32,000 per year in fees- making her studies cheaper in the USA than we would have paid for the same degree in South Africa.</p>
      </div>
     </div>
    </div>
    <div id='contact-us'  className='contact-us'>
    <div data-aos="fade-up"  data-aos-delay="100" data-aos-easing="ease-in-out" className='style-element'>
          <p >GET IN TOUCH</p>
            </div>
            
     <h1 data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-out" className='staff-title'>Contact Us</h1>
     <div data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-out" className='call-us'>
      <img src='phone.png' className='phone' alt='phone' />
    082 873 3000</div>
     <form onSubmit={handleSupportMessage} data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='contact-form'>
      <label className='label'>Full Name</label>
      <input value={fullname} onChange={(e) => setFullname(e.target.value)} className='input' />
      <label className='label'>Email</label>
      <input value={email} onChange={(e) => setEmail(e.target.value)} className='input' />
      <label className='label'>Phone</label>
      <input value={phone} onChange={(e) => setPhone(e.target.value)} className='input' />
      <label className='label'>Message</label>
      <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='text-area' />
      <div className='button-area '>
      <button type='submit' data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" className='learn-more'>Send</button>

      </div>
      
     </form>

    </div>
    </div>
  )
}

export default LandingPage