import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Header() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
    });

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleApplicationRedirect = () =>{
    window.location.href='/application'
  }

  return (
    <div
      data-aos="fade-right"
      data-aos-easing="ease-in-out"
      className={`header ${scrolling ? "scrolled" : ""}`}
    >
      <div className="image">
        <img src="headerlogo.png" className="logo" alt="logo" />
      </div>
      <div className={`options-header ${scrolling ? "scrolled-options" : ""}`}>
        <p className="option" onClick={() => handleScrollTo('about-us')}>About Us</p>
        <p className="option" onClick={() => handleScrollTo('process')}>Process</p>
        <p className="option" onClick={() => handleApplicationRedirect()}>Application</p>
        <p className="option" onClick={() => handleScrollTo('contact-us')}>Contact Us</p>
      </div>
      <div className="button-header">
        <button onClick={() => handleApplicationRedirect()} className="apply">Apply</button>
      </div>
    </div>
  );
}

export default Header;

