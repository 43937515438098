import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css"; 
import { useToast } from '../Context/ToastContext';
import axios from 'axios';


function Application() {
  const toast = useToast();
  const [athlete_name, setAthName] = useState('');
  const [athlete_email, setAthEmail] = useState('');
  const [parents_phone, setAthPhone] = useState('');
  const [athlete_class, setClass] = useState('');
  const [sport, setSport] = useState('');
  const [parents_name, setParName] = useState('');
  const [parents_email, setParEmail] = useState('');
  const [address, setAddress] = useState('');
  const [I_am_athlete, setAthlete] = useState('');
  const [I_am_parent, setParent] = useState('');

   useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration in milliseconds
        once: true, // Whether animation should happen only once
        offset: 100, // Distance before the element triggers animation
      });
    }, []);
  
  const handleSubmit = async(e) =>{
    e.preventDefault();

    const loadingToastId = toast.loading("Loading...");

    
    try {
      const response = await axios.post('https://sport-scholarship-usa.onrender.com/new-application', {athlete_name, athlete_email, parents_phone, athlete_class, sport, parents_name, parents_email, address, I_am_athlete, I_am_parent});
      if (response.status === 200) {
        // Update the loading toast to success
        toast.update(loadingToastId, {
          type: "success",
          message: response.data.message,
          autoClose: 2000,
        });
  
       
          window.location.href = '/success-application';
   
      }
    } catch (error) {
      // Update the loading toast to error
      toast.update(loadingToastId, {
        type: "error",
        message: error.response.data.message || 'An error occurred!',
        autoClose: 3000,
      });
    }
  }
  return (
    <div id='contact-us'  className='contact-us'>
    <div data-aos="fade-up"  data-aos-delay="100" data-aos-easing="ease-in-out" className='style-element-app'>
          <p >GET STARTED</p>
            </div>
     <h1 data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-out" className='staff-title'>Application</h1>
     <form onSubmit={handleSubmit} data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out" className='contact-form'>

     <div className='form-div'>
      <div className='input-div'> 
        <label className='label-small'>Athlete's Fullname</label>
        <input onChange={(e) =>setAthName(e.target.value)} className='small-input'></input>
      </div>
      <div className='input-div'>
      <label className='label-small'>Athlete's Email</label>
      <input onChange={(e) =>setAthEmail(e.target.value)}  className='small-input'></input>
      </div>
     </div>
     
     <div className='form-div'>
      <div className='input-div'> 
        <label className='label-small'>Athlete's Class</label>
        <input onChange={(e) =>setClass(e.target.value)}  className='small-input'></input>
      </div>
      <div className='input-div'>
      <label className='label-small'>Sport</label>
      <input onChange={(e) =>setSport(e.target.value)}  className='small-input'></input>
      </div>
     </div>

     <div className='form-div'>
      <div className='input-div'> 
        <label className='label-small'>Parent's Name</label>
        <input onChange={(e) =>setParName(e.target.value)}  className='small-input'></input>
      </div>
      <div className='input-div'>
      <label className='label-small'>Parent's Email</label>
      <input onChange={(e) =>setParEmail(e.target.value)}  className='small-input'></input>
      </div>
     </div>

     <div className='form-div'>
      <div className='input-div'> 
        <label className='label-small'>Parent's Phone</label>
        <input onChange={(e) =>setAthPhone(e.target.value)}  className='small-input'></input>
      </div>
      <div className='input-div'>
      <label className='label-small'>Address</label>
      <input onChange={(e) =>setAddress(e.target.value)}  className='small-input'></input>
      </div>
     </div>
     <div className='check-div'>
  <input
    type='radio'
    name='role'
    value="true"
    checked={I_am_athlete === "true"}
    onChange={() => {
      setAthlete("true");
      setParent("false");
    }}
  />
  <p className='label'>I'm an athlete</p>
</div>

<div className='check-div'>
  <input
    type='radio'
    name='role'
    value="true"
    checked={I_am_parent === "true"}
    onChange={() => {
      setAthlete("false");
      setParent("true");
    }}
  />
  <p className='label'>I'm a parent</p>
</div>


     
      <div className='button-area '>
      <button type='submit' data-aos="fade-up" data-aos-delay="400" data-aos-easing="ease-in-out" className='learn-more'>Submit</button>

      </div>
     </form>
    </div>
  )
}

export default Application