import React from 'react'

function NotFound() {
  const handleRedirect = () =>{
    window.location.href='/'
  }
  return (
    <div className='not-found'>
      <img src='4040.png' className='not-found-img' alt='not-found' style={{width:'300px'}} />
      <button onClick={()=>handleRedirect()} className='apply'>Back Home</button> 
    </div>
  )
}

export default NotFound